// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import HoldScene from "components/shared/HoldScene"
import ButtonHold from "components/shared/ButtonHold"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    
 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Act15 extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="act15Wrapper">
                <div id="act15Scene"/>
                <Controller>
                    <Scene
                        triggerElement="#act15Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                    noTitleBar
                                />

                                <CopyBox
                                    copyVerticalPosition="toTop"
                                    copyVerticalPositionMd="toTop"
                                    copyHorizontalPosition="toRight"
                                    step1
                                >
                                    <p>
                                        The rest of the Pride believe Simba to be dead and accept Scar as their new
                                        king.
                                    </p>
                                    <p>
                                        What kind of king is Scar?
                                    </p>
                                </CopyBox>

                                <ButtonHold
                                    play={progress > 0}
                                    target={this.act15HoldScene}
                                />

                                <HoldScene
                                    ref={act15HoldScene => this.act15HoldScene = act15HoldScene}
                                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                                    text="<p>Scar is a lazy and inefficient king, and invites the hyenas to the Pridelands.</p><p>The devastation of the Pridelands begins.</p>"
                                    quote={false}
                                    color="orange"
                                    copyHorizontalPosition="toRight"
                                    copyVerticalPositionMd="toTop"
                                    copyVerticalPosition="toTop"
                                />

                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-1-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-1-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-1-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-1-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
               bgStep2: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-2-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-2-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-2-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt5-2-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Act15 data={data} active={props.active} title={props.title}/>
        )}
    />
)
