// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';
import {media} from "utils/Media"
import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Act25 extends Component {
    render() {
        return (
            <Wrapper active={this.props.active} id="act25Wrapper">
                <div id="act25Scene" ref={act25Scene => this.act25Scene = act25Scene}/>
                <Controller>
                    <Scene
                        triggerElement="#act25Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => (
                            <div className={`sticky2`}>
                                <BgImage
                                    data={this.props.data}
                                    play={progress > 0}
                                    progress={progress}
                                    noTitleBar
                                />

                                <CopyBox copyHorizontalPosition="toLeft"
                                         step1
                                         copyVerticalPositionMd="toTop"
                                >
                                    <p>
                                        They find the land dry and bare. Simba confronts Scar on Pride
                                        Rock and forces him to admit that he killed Mufasa. Scar tries to blame the
                                        hyenas,
                                        then lunges at Simba who knocks Scar off the rock in self defence. Scar falls
                                        into the
                                        clutches of the hungry hyenas below.
                                    </p>
                                </CopyBox>
                            </div>
                        )}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt11-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt11-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt11-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt11-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Act25 data={data} active={props.active} title={props.title}/>
        )}
    />
)
