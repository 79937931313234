import React, {Component} from "react"
import {media} from "utils/Media"
import styled from "styled-components"
import LayoutInteractive from "components/Layout/LayoutInteractive"
import ParallaxImage from "components/Interactive/ParallaxImage"
import Menu from "components/Interactive/Menu"
import TitleBar from 'components/shared/TitleBar'
import ActTitle from "components/Interactive/ActTitle"
import Act11 from "components/Interactive/TheStory/Act11"
import Act12 from "components/Interactive/TheStory/Act12"
import Act13 from "components/Interactive/TheStory/Act13"
import Act14 from "components/Interactive/TheStory/Act14"
import Act15 from "components/Interactive/TheStory/Act15"
import Act16 from "components/Interactive/TheStory/Act16"
import Act21 from "components/Interactive/TheStory/Act21"
import Act22 from "components/Interactive/TheStory/Act22"
import Act23 from "components/Interactive/TheStory/Act23"
import Act24 from "components/Interactive/TheStory/Act24"
import Act25 from "components/Interactive/TheStory/Act25"
import Act26 from "components/Interactive/TheStory/Act26"

import DownloadButton from "components/shared/DownloadButton"
import IconDownload from 'images/icons/icon-download-white.svg'

import {graphql, StaticQuery} from "gatsby";
import {isBrowser, isChrome} from "react-device-detect";

const MobileTitleBar = styled(TitleBar)`
    display: block;
    cursor: pointer;
    
    &.hidden {
        display: none;
    }
    
    @media ${media.sm} {
        display: none;
        
        &.hidden {
            display: block;
        }
    }
`

const scrollToElement = require('scroll-to-element');

const Query = () => (
    <StaticQuery
        query={graphql`
            query {
                image: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-header-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                imageLg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-header-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
               
                imageSm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-header-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                imageXs: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-header-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <TheStoryPage data={data}/>
        )}
    />
)

class TheStoryPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            scene: '',
            displayContent: false,
            imagesAreLoaded: [],
            activeSceneName: "Act 1",
            activePageName: "1/3 The Story",
            sceneList: [
                {
                    name: "Act 1",
                    slug: "act11",
                    color: "yellow",
                    active: true
                },
                {
                    name: "Act 2",
                    slug: "act21",
                    color: "orange",
                    active: false
                }
            ],
            pageList: [
                {
                    name: "1/3 The Story",
                    slug: "/interactive/the-story",
                    active: true
                },
                {
                    name: "2/3 Meet the Characters",
                    slug: "/interactive/meet-the-characters",
                    active: false
                },
                {
                    name: "3/3 About The Production",
                    slug: "/interactive/about-the-production",
                    active: false,
                },
                // {
                //     name: "4/4 Fascinating Facts",
                //     slug: "/interactive/fascinating-facts",
                //     active: false,
                // }
            ]
        }
    }

    toggleScene = (scene) => {
        this.setState({scene}, () => {
            scrollToElement(`#${scene}Scene`, {
                duration: 300
            })
        })

    }

    imagesLoaded = (isLoaded) => {
        let imagesAreLoaded = this.state.imagesAreLoaded
        let displayContent = this.state.displayContent

        imagesAreLoaded.push(isLoaded)

        if (isBrowser) {
            if (imagesAreLoaded.length === 1) {
                displayContent = true
            }
        } else {
            displayContent = true
        }

        this.setState({imagesAreLoaded, displayContent})
    }

    render() {
        return (
            <LayoutInteractive slug="interactive/the-story" title="The Story">
                <Menu
                    sceneList={this.state.sceneList}
                    activeSceneName={this.state.activeSceneName}
                    pageList={this.state.pageList}
                />
                <ParallaxImage imagesAreLoaded={this.imagesLoaded} data={this.props.data} title="The Story"/>

                {this.state.displayContent &&
                <React.Fragment>
                    <MobileTitleBar
                        className={this.state.scene === 'act11' ? 'hidden' : ''}
                        color="yellow"
                        lessPadding
                        onClick={() => this.toggleScene('act11')}
                    >
                        <h2>Act 1</h2>
                    </MobileTitleBar>

                    <ActTitle active={this.state.scene === 'act11'} color="yellow" title="Act 1"/>
                    <Act11 active={this.state.scene === 'act11'}/>
                    <Act12 active={this.state.scene === 'act11'}/>
                    <Act13 active={this.state.scene === 'act11'}/>
                    <Act14 active={this.state.scene === 'act11'}/>
                    <Act15 active={this.state.scene === 'act11'}/>
                    <Act16 active={this.state.scene === 'act11'}/>

                    <MobileTitleBar
                        className={this.state.scene === 'act21' ? 'hidden' : ''}
                        color="orange"
                        lessPadding
                        onClick={() => this.toggleScene('act21')}
                    >
                        <h2>Act 2</h2>
                    </MobileTitleBar>

                    <ActTitle active={this.state.scene === 'act21'} color="orange" title="Act 2"/>
                    <Act21 active={this.state.scene === 'act21'}/>
                    <Act22 active={this.state.scene === 'act21'}/>
                    <Act23 active={this.state.scene === 'act21'}/>
                    <Act24 active={this.state.scene === 'act21'}/>
                    <Act25 active={this.state.scene === 'act21'}/>
                    <Act26 active={this.state.scene === 'act21'}/>
                </React.Fragment>
                }

                <DownloadButton className="mx-auto mb-4 mt-5" download target="_blank" href="/pdfs/Disneys_The_Lion_King_The_Story.pdf">Download&nbsp;PDF <img src={IconDownload} alt="" /></DownloadButton>
            </LayoutInteractive>
        )
    }
}

export default Query