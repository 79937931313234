// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';

import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import CopyBox from "components/shared/CopyBox"
import {GatsbyImage} from "gatsby-plugin-image";
import CopyBoxWrapper from "components/shared/CopyBoxWrapper"
import {Tween, Timeline} from 'react-gsap';
import ButtonScroll from "components/shared/ScrollButton"

const Relative = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const Wrapper = styled.div`
  overflow: hidden;
  
  .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 {
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
     .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
    
    .animation__background {
         width: 100vw;
        height: 100vh;
        position: absolute;
        left: 0;
        z-index: 2;
        top: 0;
        
        &--step2 {
            opacity: 0;
        }
        
        .image {
            width: 100vw;
            height: 100vh;
        }
    }
    
    .animation__scar {
        width: 100vw;
        height: 100vh;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        
        .scar {
            display: flex;
            position: absolute;
            bottom: 80px;
            right: 0;
            z-index: 6;
            width: 100vw;
            
            @media ${media.sm} {
              bottom: 10px;
              right: -80px;
              max-width: 580px;
            }
            @media ${media.md} {
              right: 0;
              bottom: 40px;
              max-width: 500px;  
            }
            
            @media ${media.lg} {
              bottom: 100px;
              max-width: 350px;
            }
            
            @media ${media.xl} {
              min-width: 580px;
            }
            
            .image { 
                width: 100%;
            }
        }
    }
`;

class Act11 extends Component {
    displayScene = (progress) => {
        return (
            <div className="sticky2">
                <Timeline totalProgress={progress} paused>
                    <div className="animation__background">
                        <GatsbyImage image={this.props.data.bgStep1Sm.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="image d-block d-sm-none"
                        />
                        <GatsbyImage image={this.props.data.bgStep1Md.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="image d-none d-sm-block d-lg-none"
                        />
                        <GatsbyImage image={this.props.data.bgStep1Lg.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="image d-none d-lg-block d-xl-none"
                        />
                        <GatsbyImage image={this.props.data.bgStep1.childImageSharp.gatsbyImageData}
                            alt=""
                            title=""
                            className="image d-none d-xl-block"
                        />
                    </div>
                    <CopyBoxWrapper/>

                    <Tween
                        to={{opacity: 0}}
                        duration={3}
                    >
                        <CopyBoxWrapper noBackground>
                            <Relative>
                                <CopyBox
                                    copyHorizontalPosition="toRight"
                                    step1
                                    copyVerticalPositionMd="toBottom"
                                    copyVerticalPositionXs="toBottom"
                                    copyHorizontalPositionMd="toLeft"
                                >
                                    <p>
                                        It is sunrise over Pride Rock. Rafiki calls the animals to witness as
                                        Mufasa, ‘The Lion King’, and his queen, Sarabi, present their
                                        newborn son, Simba to the delighted animals.
                                    </p>
                                    <p>
                                        Were all the animals delighted?
                                    </p>
                                </CopyBox>
                            </Relative>
                        </CopyBoxWrapper>
                    </Tween>

                    <Tween
                        from={{y: '100%'}}
                        duration={3}
                    >
                        <div className="animation__scar slideInUp">
                            <Relative>
                                <div className="scar">
                                    <GatsbyImage image={this.props.data.scarImage.childImageSharp.gatsbyImageData}
                                        alt=""
                                        title=""
                                        className="image d-block"
                                    />
                                </div>
                            </Relative>
                        </div>
                    </Tween>
                    <Tween
                        to={{opacity: 1}}
                        duration={3}
                    >
                        <CopyBoxWrapper noBackground style={{opacity: 0}}>
                            <Relative>
                                <CopyBox copyHorizontalPosition="toRight"
                                         copyHorizontalPositionMd="toLeft"
                                         copyVerticalPosition="toBottom"
                                         copyVerticalPositionMd="toBottom"
                                         copyVerticalPositionXs="toBottom"
                                >
                                    <p>
                                        No. Scar, Mufasa’s brother, resents Simba as he feels he himself should be next
                                        in line
                                        to be King.
                                    </p>
                                </CopyBox>
                            </Relative>
                        </CopyBoxWrapper>
                    </Tween>
                </Timeline>
                <ButtonScroll story progress={progress}/>
            </div>
        )
    }

    render() {
        return (
            <Wrapper active={this.props.active} id="act11Wrapper">
                <div id="act11Scene" ref={act11Scene => this.act11Scene = act11Scene}/>
                <Controller>
                    <Scene
                        triggerElement="#act11Scene"
                        triggerHook="onLeave"
                        duration={2000}
                        pin
                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgStep1: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt1-1-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt1-1-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt1-1-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt1-1-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
                scarImage: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt1-2-1920.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100, placeholder: NONE, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
		`}
        render={data => (
            <Act11 data={data} active={props.active} title={props.title}/>
        )}
    />
)
