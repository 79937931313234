// @flow
import React, {Component} from 'react';
import styled, {css} from 'styled-components';

import {Controller, Scene} from 'react-scrollmagic';
import {graphql, StaticQuery} from "gatsby";
import {media} from "utils/Media"
import SwipeScene from "components/shared/SwipeScene"
import ButtonSwipe from "components/shared/ButtonSwipe"
import CopyBox from "components/shared/CopyBox"
import BgImage from 'components/shared/BgImage'

const Wrapper = styled.div`
    overflow: hidden;
    position: relative;

 .scrollmagic-pin-spacer {
    display: none !important;

    ${props => props.active && css`
        display: block !important;
    `}

    @media ${media.sm} {
        display: block !important;
    }
  }
  
  .sticky, .sticky2 { 
    height: 0;
    overflow: hidden;
    width: 100% !important;
    
    ${props => props.active && css`
        height: 100vh;
    `}
    
    @media ${media.sm} {
        height: 100vh;
    }
  }
  
  .fs {
        height: 100vh;
    }
    
  .slideInUp {
        .no-touch & {
            visibility: hidden;
            opacity: 0;
            transition: all 0.7s ease-out;
            transform: translate3d(0, 50px, 0);
        }
        .no-touch .is-active & {
            visibility: visible;
            opacity: 1;
            transform: translate3d(0, 0, 0);
        }
    }
    
    .pinContainer {
        position: absolute;
        top: 0;
        left: 0;
        height: 100vh;
        width: 100vw;
    }
`;

class Act14 extends Component {
    displayScene = (progress) => {
        return (
            <div className="sticky2">
                <BgImage
                    data={this.props.data}
                    play={progress > 0}
                    progress={progress}
                    noTitleBar
                />

                <ButtonSwipe
                    target={this.act14Swipe}
                    play={progress > 0}
                />

                <CopyBox copyHorizontalPosition="toRight"
                         step1
                         copyVerticalPositionMd="toTop"
                         copyHorizontalPositionMd="toLeft"
                >
                    <p>
                        Scar continues to plot the downfall of Mufasa. He lures Simba into a gorge and starts a stampede
                        of wildebeest. Mufasa arrives in time to save Simba but does not survive.
                    </p>
                </CopyBox>

                <SwipeScene
                    ref={act14Swipe => this.act14Swipe = act14Swipe}
                    text="<p>Scar makes Simba believe that Mufasa’s death was  his fault and persuades  him to run away from the Pridelands, forever.</p>"
                    quote={false}
                    color="orange"
                    bgImageSm={this.props.data.bgStep2Sm.childImageSharp.gatsbyImageData}
                    bgImageMd={this.props.data.bgStep2Md.childImageSharp.gatsbyImageData}
                    bgImageLg={this.props.data.bgStep2Lg.childImageSharp.gatsbyImageData}
                    bgImageXl={this.props.data.bgStep2.childImageSharp.gatsbyImageData}
                    copyHorizontalPosition="toRight"
                    copyVerticalPositionMd="toTop"
                    copyHorizontalPositionMd="toLeft"
                />
            </div>
        )
    }

    render() {
        return (
            <Wrapper active={this.props.active} id="act14Wrapper">
                <div id="act14Scene" ref={act14Scene => this.act14Scene = act14Scene}/>
                <Controller>
                    <Scene
                        triggerElement="#act14Scene"
                        triggerHook="onLeave"
                        duration={500}
                        pin
                    >
                        {(progress) => this.displayScene(progress)}
                    </Scene>
                </Controller>
            </Wrapper>
        )
    }
}


export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                 bgStep1: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-1-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep1Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-1-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep1Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-1-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep1Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-1-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
                
               bgStep2: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-2-1920.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100)
                    }
                }
                
                bgStep2Lg: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-2-1440.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1440, quality: 100)
                    }
                }
                
                bgStep2Md: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-2-991.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991, quality: 100)
                    }
                }
                
                bgStep2Sm: file(relativePath: { eq: "Interactive/TheStory/lke-im-story-pt4-2-576.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 575, quality: 100)
                    }
                }
            }
		`}
        render={data => (
            <Act14 data={data} active={props.active} title={props.title}/>
        )}
    />
)
